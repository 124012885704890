import React from 'react';
import './App.css';
import { Button } from '@material-ui/core';

function App() {

  const blurb = [
    "Babbacombe Hall is a detached Torquay villa which has been carefully restored to create a holiday home which is ideal for celebrations, family reunions or fun filled breaks at the seaside.",
    "It has 8 bedrooms which sleep 16,  all have en-suite bathrooms - so nobody has to share! The bedrooms all have king, super king or twin beds. The kitchen is well equipped with a double oven, microwave, fridge freezer, washer/dryer, dishwasher and wine cooler.",
    "The large lounge has a smart TV and Blu-ray player.",
    "There is a large enclosed garden with built in furniture, 2 barbecues, a boules pitch and a hot tub.",
  ];

  const areaBlurb = [
    "Affectionately known as the ‘Jewel in the crown of the English Riviera', Babbacombe includes a great range of things to do and see in a quiet, laid back setting. Within walking distance are Babbacombe model village, Oddicombe beach with its cliff top funicular railway and Babbacombe theatre.",
    "Make sure you walk along the picturesque Babbacombe downs which has the highest cliff top promenade in England with spectacular views across Lyme bay to Dorset.",
    "With warm weather all year round it's the ideal location to enjoy the best of South Devon."
  ];

  const images = [
    "bedroom",
    "dining",
    "bedroom2",
    "lounge",
    "bathroom",
    "kitchen",
    "side-table",
    "garden",
    "front",
    "hottub",
  ];

  return (
    <div className="App">
      <div className="header">
        <img alt="" src={process.env.PUBLIC_URL + "/banner.png"} />
      </div>
      <div className="main">
        <div className="row">
          <div className="blurb">
            {blurb.map((b, i) => <div key={i}>{b}</div>)}
          </div>
          <div className="spacer" />
          <div className="booking">
            <Button
              href="https://www.helpfulholidays.co.uk/cottage/Devon-Torquay/Babbacombe-Hall-998470.html"
              variant="contained"
              color="primary"
            >
              Pricing and Availability
            </Button>
            <Button
              href="mailto:stay@babbacombehall.co.uk"
              variant="contained"
              color="primary"
            >
              Contact Babbacombe Hall
            </Button>
          </div>
        </div>
        <div className="images">
          {images.map(i => (<div className="image"><img src={process.env.PUBLIC_URL + "/images/" + i + ".jpg"} alt={"Babbacombe Hall Feature: " + i} /></div>))}
        </div>
        <div className="row">
          <div className="blurb">
            {areaBlurb.map((b, i) => <div key={i}>{b}</div>)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
